<!--
 * @Descripttion:
 * @version:
 * @Author: 张天鹏
 * @Date: 2020-04-29 14:57:34
 * @LastEditors: 王广婷
 * @LastEditTime: 2020-05-07 19:34:16
 -->
<template>
    <div class="content">
        <div class="title">
             <img src="@/assets/nh/servicetitle.png" alt="">
            <span @click="activity">
              <img src="@/assets/nh/activity.png" alt="">
            </span>
        </div>
        <div>
            <ul class="service-list">
                <li @click="$router.push({path:'/serviceDetail',query:{detailid:'srys'}})"><img src="@/assets/nh/srys.png" alt=""></li>
                <li @click="$router.push({path:'/serviceDetail',query:{detailid:'zjwz'}})"><img src="@/assets/nh/zjwz.png" alt=""></li>
                <li @click="$router.push({path:'/serviceDetail',query:{detailid:'zzlt'}})"><img src="@/assets/nh/zzlt.png" alt=""></li>
                <li @click="$router.push({path:'/serviceDetail',query:{detailid:'ecqz'}})"><img src="@/assets/nh/ecqz.png" alt=""></li>
                <li @click="$router.push({path:'/serviceDetail',query:{detailid:'jswz'}})"><img src="@/assets/nh/jswz.png" alt=""></li>
            </ul>
        </div>
         <md-popup v-model="activityshow" :mask-closable="closable">
            <div class="details">
                <div class="deailestitle">
                    <p>客户保单福利规则</p>
                </div>
                <div class="detailscon">
                    <p class="detailsconone">单笔保费满即送价值万元健康医疗服务大礼包</p>
                    <p class="detailsconspan"><span class="detailsconimg"><img src="@/assets/nh/activityone.png" alt=""></span>单笔保费<span>满5000元送价值999元</span>，全年无限次三甲医院私人医生在线问诊</p>
                    <p class="detailsconspan"><span class="detailsconimg"><img src="@/assets/nh/activitytwo.png" alt=""></span>单笔保费<span>满8000元送价值3999元</span>，全年无限次三甲医院私人医生在线问诊+三次免费三甲医院专科医生转诊预约</p>
                    <p class="detailsconspan"><span class="detailsconimg"><img src="@/assets/nh/activitythree.png" alt=""></span>单笔保费<span>满30000元送价值15999元</span>，全年无限次三甲医院私人医生在线问诊+三次免费三甲医院专科医生转诊预约+3次急速问诊+1次重疾绿通+1次二次专家诊疗</p>
                </div>
            </div>
            <div class="shutdown">
              <img @click="activityshutdown" src="@/assets/nh/activitycuo.png" alt="">
            </div>
        </md-popup>
    </div>
</template>
<script>
import { stopScroll, canScroll } from '@/lib/util'
export default {
  data () {
    return {
      activityshow: false,
      closable: false
    }
  },
  watch: {
    activityshow (val) {
      if (val) {
        this.prods = []
        stopScroll()
      } else {
        this.prods = []
        canScroll()
      }
    }
  },
  methods: {
    activity () {
      this.activityshow = true
    },
    activityshutdown () {
      this.activityshow = false
    }
  }
}
</script>
<style lang="stylus" scoped>
    .content{
        margin 0.41rem 0.27rem 0.41rem
        .title{
            width 70%
            margin auto
            margin-bottom 0.95rem
        }
        .title span {
            position: absolute;
            right: 0;
            top: 36px;
            line-height: 90px;
            width: 140px;
            text-align: center;
        }
        .service-list{
            li{
                margin-bottom 0.15rem
            }
        }
        .details{
            background #fff
            width 90vw
            height 60vh
            border-radius: 0.3rem;
            overflow-y:auto
        }
        .deailestitle{
            border-bottom 1px solid #eee
        }
        .deailestitle p{
            font-size 43px
            text-align: center;
            height: 1.3rem;
            line-height: 1.3rem;
            font-weight: 600;
        }
        .shutdown{
            margin-top 40px
            text-align center
        }
        .shutdown img{
            width: 0.7rem;
        }
        .detailscon{
            width 90%
            margin 0 auto
        }
        .detailsconone{
            color #585858
            height 1rem
            line-height 1rem
        }
        .detailsconimg{
            margin-left: 0.5rem;
        }
        .detailsconimg img{
            position relative
            top 10px
            left -0.5rem
            width 0.55rem
        }
        .detailsconspan{
            margin 0.2rem 0
            color #585858
        }
        .detailsconspan span{
            font-weight 600
            color #000
        }
    }
</style>
